.treatment {
  width: 100%;
  height: 820px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.treatment-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #777777;
}

.treatment-sub {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 55%;
}

.treatment-body {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #1e1e1e;
}

.treatment form {
  width: 78%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.treatment-flex {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
}

label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #777777;
}

input,
select {
  height: 50px;
  background: #f8f8f8;
  border-radius: 8px;
  border: none;
  outline: none;
  padding: 0px 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #1e1e1e;
}

select {
  cursor: pointer;
}

input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #d3d3d3;
}

.treatment-btn {
  width: 235px;
  height: 50px;
  background: linear-gradient(
    93.09deg,
    #2b600b 3.35%,
    #3e8910 45.73%,
    #df943c 97.9%
  );
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  margin: 20px auto;
  cursor: pointer;
}

.treat-non {
  width: 235px;
  height: 50px;
  background: #d3d3d3;
  cursor: default;
  border-radius: 8px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  margin: 20px auto;
}

.msl {
  background: #f8f8f8;
  border: none !important;
  min-height: 50px;
  margin-top: 10px;
  width: 100%;
  cursor: pointer !important;
}

.msl-vars {
  --input-width: 100% !important;
}

.none{
  height: 0.5px;
  background: #fff;
  cursor: default;
  border: none;
  outline: none;
  color: #fff;
}

.pull{
  margin-bottom: -20px;
}

@media (max-width: 767px) {
  .treatment {
    height: auto;
    padding: 40px 0;
  }
  .treatment-title {
    font-size: 16px;
  }
  .treatment-sub {
    width: 90%;
  }
  .treatment-body {
    font-size: 14px;
  }
  .treatment form {
    width: 90%;
  }
  .treatment-flex {
    grid-template-columns: repeat(1, 1fr);
  }
  .treatment-btn, .treat-non {
    width: 100%;
  }
}
