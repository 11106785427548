.testimonial {
    width: 100%;
    height: 540px;
    background: #FBFEF9;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.testimonial-title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #777777;
}

.testimonial-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #1E1E1E;
}

.testimonial-contain {
    width: 80%;
    display: grid;
    grid-template-columns: 24% 24% 24% 24%;
    gap: 1%;
    margin-top: 10px;
}

.testimonial-card {
    height: 220px;
    padding: 25px 12px 32px 12px;
    background: #F8F8F8;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.testimonial-star {
    display: flex;
    align-items: center;
}

.testimonial-body {
    height: 85px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #5B5B5B;
}

.testimonial-author {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.testimonial-person {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    color: #1E1E1E;
}

.testimonial-job {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #777777;
}

@media (max-width: 991px){
    .testimonial{
        background: #ffffff;
    }
    .testimonial-title{
        font-size: 16px;
    }
    .testimonial-sub{
        font-size: 14px;
        text-align: center;
        width: 85%;
    }
    .testimonial-contain{
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: 95%;
        overflow-x: scroll;
    }
    .testimonial-card{
        width: 175px;
        height: 235px;
        justify-content: flex-start;
        gap: 30px;
    }
    .testimonial-star img{
        width: 16px;
        height: 16px;
    }
    .testimonial-body{
        font-size: 12px;
        height: auto;
        width: 175px;
    }
    .testimonial-author img{
        width: 32px;
        height: 32px;
    }
    .testimonial-person{
        font-size: 14px;
    }
    .testimonial-job{
        font-size: 12px;
    }
}