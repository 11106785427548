.nav-contain {
    position: relative;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #fffcf7;
}

.nav {
    height: 100px;
    width: 85%;
    background: #fffcf7;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.logo-div{
    width: 230px;
    height: 70px;
}

.logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.flex {
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;
}

.nav-link {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #1E1E1E;
    cursor: pointer;
}

.nav-btn {
    width: 180px;
    height: 55px;
    background: linear-gradient(93.09deg, #2B600B 3.35%, #3E8910 45.73%, #DF943C 97.9%);
    border-radius: 8px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.menu-img{
    width: 40px;
    height: 40px;
    object-fit: cover;
    cursor: pointer;
}

.close{
    font-size: 24px;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width: 991px){
    .isOpen{
        flex-direction: column;
        align-items: center;
        height: 100vh;
        justify-content: flex-start;
        position: fixed;
        z-index: 999999;
    }
    .nav-drop{
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 20px;
    }
    .flex{
        display: none;
    }
}

@media (max-width: 480px){
    .nav{
        width: 93%;
    }
}