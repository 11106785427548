* {
    margin: 0;
    padding: 0;
    font-family: 'Sora', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

html,
body {
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
}

a{
    text-decoration: none;
}

.mobile{
    display: none;
}

@media (max-width: 991px){
    .mobile{
        display: flex;
    }
    .desktop{
        display: none;
    }
}