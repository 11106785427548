.footer {
    width: 100%;
    height: 380px;
    background: #2B600B;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.up-footer {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-main {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.footer-head {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    color: #FFFFFF;
    width: 265px;
}

.foot-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.socials {
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.socials img{
    height: 33px;
}

.copyright {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #CACACA;
    margin-top: 8px;
}

.footer-nav {
    display: flex;
    flex-direction: row;
    gap: 120px;
}

.footer-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #FFFFFF;
}

.footer-link {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #E1E1E1;
    cursor: pointer;
}

.reduced {
    width: 190px;
}

.down-footer {
    width: 80%;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-between;
}

.footer-end {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #E1F9D2;
}

@media (max-width: 991px){
    .footer{
        height: auto;
        gap: 56px;
    }
    .up-footer{
        flex-direction: column;
        gap: 40px;
        width: 85%;
    }
    .footer-nav{
        flex-direction: row;
        justify-content: space-between;
        gap: 32px;
    }
    .reduced{
        width: 210px;
    }
    .down-footer{
        width: 85%;
    }
}

@media (max-width: 767px){
    .footer{
        height: auto;
        gap: 56px;
    }
    .up-footer{
        flex-direction: column;
        gap: 40px;
        width: 85%;
    }
    .footer-nav{
        flex-direction: column;
        gap: 32px;
    }
    .reduced{
        width: 210px;
    }
    .down-footer{
        width: 85%;
    }
}

@media (max-width: 480px){
    .up-footer{
        width: 93%;
    }
    .down-footer{
        width: 93%;
    }
}