.hero {
    width: 100%;
    height: auto;
    background: #fffcf7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}

.hero-txt {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.hero-header {
    font-style: normal;
    font-weight: 700;
    font-size: 54px;
    line-height: 150%;
    text-align: center;
    color: #1E1E1E;
}

.hero-header span {
    color: #2B600B;
}

.hero-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #5B5B5B;
    width: 82%;
}

.hero-img {
    width: 80%;
    aspect-ratio: 16/9;
    object-fit: contain;
    border-radius: 8px;
}

@media (max-width: 991px){
    .hero-txt{
        width: 75%;
    }
}

@media (max-width: 767px){
    .hero{
        padding-bottom: 50px;
    }
    .hero-txt{
        width: 95%;
        gap: 20px;
    }
    .hero-header{
        font-size: 28px;   
    }
    .hero-sub{
        font-size: 14px;
        width: 95%;
    }
    .hero-img{
        width: 90%;
        height: 270px;
        object-fit: cover;
        margin-top: 40px;
    }
}