.about {
    width: 100%;
    background: #ffffff;
    height: 680px;
    display: grid;
    grid-template-columns: 60% 40%;
}

.about-txt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 84%;
    margin: 0 0 0 auto;
}

.about-title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #777777;
}

.about-header {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    color: #1E1E1E;
    margin-top: 15px;
}

.about-header span {
    color: #2B600B;
}

.about-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #1E1E1E;
    width: 75%;
}

.about-sub span {
    color: #2B600B;
}

.about-section {
    width: 100%;
    position: relative;
}

.about-img1 {
    position: absolute;
    top: 110px;
    left: -30px;
}

.about-img2 {
    position: absolute;
    top: 110px;
    left: 150px;
}

.about-img3 {
    position: absolute;
    top: 160px;
    left: 220px;
}

.about-img6 {
    position: absolute;
    top: 450px;
    left: -20px;
}

.about-img5 {
    position: absolute;
    top: 570px;
    left: 120px;
}

.about-img4 {
    position: absolute;
    top: 335px;
    left: 185px;
}

@media (max-width: 991px){
    .about{
        grid-template-columns: 55% 45%;
    }
    .about-sub{
        width: 85%;
    }
}

@media (max-width: 767px){
    .about{
        grid-template-columns: repeat(1,1fr);
        height: auto;
        padding: 50px 0 30px 0;
    }
    .about-title{
        font-size: 16px;
    }
    .about-txt{
        margin: 0;
        width: 100%;
        align-items: center;
    }
    .about-header{
        font-size: 28px;
    }
    .about-sub{
        margin-top: 20px;
        text-align: center;
        width: 90%;
        font-size: 14px;
    }
    .about-section{
        height: 370px;
    }
    .about-img1 {
        top: 30px;
        left: 20%;
        width: 100px;
        height: 175px;
        object-fit: cover;
    }
    .about-img2 {
        top: 30px;
        left: 47%;
        width: 35px;
        height: 35px;
        object-fit: cover;
    }
    .about-img3 {
        top: 70px;
        left: 57%;
        width: 80px;
        height: 80px;
        object-fit: cover;
    }
    .about-img4 {
        top: 170px;
        left: 55%;
        width: 100px;
        height: 175px;
        object-fit: cover;
    }
    .about-img5 {
        top: 300px;
        left: 42%;
        width: 35px;
        height: 35px;
        object-fit: cover;
    }
    .about-img6 {
        top: 230px;
        left: 23%;
        width: 80px;
        height: 80px;
        object-fit: cover;
    }
}