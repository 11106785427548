.del-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px 100px;
  box-sizing: border-box;
}

.val-form {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 0 auto;
  padding-top: 40px;
  gap: 40px;
}

.val-head {
  color: #4f4f4f;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}

.val-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.val-form form input {
  border: 1px solid #e0e0e0;
}

.val-pass {
  display: grid;
  grid-template-columns: 92% auto;
  width: 100%;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background: #f8f8f8;
}

.val-pass input {
  background: transparent !important;
  border: none !important;
  outline: none !important;
}

.val-btn {
  margin-top: 20px;
  border-radius: 8px;
  background: #2b600b;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 131.23%;
  cursor: pointer;
  border: none;
  outline: none;
  height: 50px;
}

.eye {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 80%;
}

.del-title {
  color: #393939;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 20px;
}

.del-text {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 90%;
}

.del-text li {
  margin-left: 40px;
}

.del-acct {
  border-radius: 8px;
  background: #fabfbf;
  max-width: 340px;
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  color: #a40b0b;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 131.23%;
  cursor: pointer;
  margin: 40px 0px 70px 0px;
}

.del-popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999999;
}

.popup {
  width: 90%;
  height: auto;
  padding: 16px 16px 25px 16px;
  background: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  margin: 55px auto;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.pop-img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.pop-cont {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.pop-head {
  color: #1e1e1e;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.pop-txt {
  color: #777;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

@media (max-width: 767px) {
  .del-body {
    padding: 30px 20px;
  }
  .del-body .logo-div {
    width: 160px;
  }
  .val-form {
    width: 100%;
    padding-top: 0px;
    gap: 25px;
  }
  .val-pass {
    grid-template-columns: 90% auto;
  }
  .val-form form label {
    font-size: 12px;
  }
  .val-form form input {
    font-size: 14px;
  }
  .val-form form input::placeholder {
    font-size: 14px;
  }
  .val-head {
    font-size: 16px;
  }
  .del-title {
    font-size: 24px;
  }
  .del-text {
    font-size: 16px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  .del-popup {
    width: fit-content;
    height: auto;
    background: transparent;
    right: 30px;
    left: unset;
  }

  .popup {
    box-shadow: rgba(0, 0, 0, 0.5) 5px 10px 15px;
  }
}
