.steps {
    width: 80%;
    padding: 80px 0px;
    margin: 0 auto;
    height: 950px;
    background: #FFFFFF;
}

.steps-title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #777777;
    margin-bottom: 30px;
}

.steps-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #1e1e1e;
    width: 53%;
}

.steps-sub span {
    color: #2B600B;
}

.steps-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.step-flow {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}

.step-card-1:hover {
    width: 100%;
    padding: 20px 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-left: 3px solid #DF943C;
    transition: 0.5s ease-in;
}

.step-card-1 {
    width: 100%;
    padding: 20px 10px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-left: 3px solid #E3E3E3;
}

.step-card-1:hover .step-head-1 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin-left: 20px;
    transition: 0.5s ease-in;
}

.step-head-1 {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.step-card-1:hover .step-icon-1 {
    width: 10px;
    height: 10px;
    background: #2B600B;
    transition: 0.5s ease-in;
}

.step-icon-1 {
    width: 10px;
    height: 10px;
    background: #D3D3D3;
    border-radius: 50%;
}

.step-card-1:hover .step-header-1 {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 150%;
    color: #2B600B;
    transition: 0.5s ease-in;
}

.step-header-1 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 150%;
    color: #D3D3D3;
}

.step-card-1:hover .step-body-1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #1E1E1E;
    width: 90%;
    transition: 0.5s ease-in;
}

.step-body-1 {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #D3D3D3;
    width: 78%;
}

@media (max-width: 991px){
    .steps{
        display: none;
    }
}