.download-contain {
    width: 100%;
    height: 340px;
    background: #FBFEF9;
}

.download {
    width: 80%;
    height: 340px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
}

.download-title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #777777;
}

.download-flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.download-txt {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 150%;
    color: #1E1E1E;
    width: 60%;
}

.download-txt span {
    color: #2B600B
}

.download-wrap {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.playstore {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 190px;
    height: 60px;
    background: #2B600B;
    border-radius: 8px;
    padding: 12px 15px;
}

.playstore img {
    width: 33px;
    height: 37px;
    object-fit: cover;
}

.play-txt {
    display: flex;
    flex-direction: column;
}

.play-body {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #D9D9D9;
}

.play-name {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #FFFFFF;
}

@media (max-width: 991px){
    .download-contain{
        height: auto;
        padding: 40px 0;
    }
    .download{
        height: auto;
        align-items: center;
    }
    .download-flex{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .download-txt{
        text-align: center;
    }
}

@media (max-width: 767px){
    .download-contain{
        height: auto;
        padding: 40px 0;
    }
    .download{
        height: auto;
        width: 90%;
        align-items: center;
    }
    .download-flex{
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
    .download-title{
        font-size: 16px;
    }
    .download-txt{
        width: 90%;
        font-size: 28px;
        text-align: center;
    }
    .download-wrap{
        flex-direction: column;
    }
    .playstore{
        margin-bottom: 10px;
    }
}