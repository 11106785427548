.services {
    height: 720px;
    width: 100%;
    background: #FBFEF9;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.services-contain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 80%;
    margin: 0 auto;
}

.yellow {
    border: 1px solid #B37400;
    background: #FFF6E6;
}

.purple {
    background: #FFE6FF;
    border: 1px solid #330033;
    margin: 0 0 0 auto !important;
}

.green {
    background: #E6FFE6;
    border: 1px solid #003300;
}

.yel {
    background: #ffa500;
}

.pur {
    background: #800080;
}

.gre {
    background: #008000;
}

.service-card {
    width: 60%;
    height: 175px;
    border-radius: 8px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 12px;
}

.service-head {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.service-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

.service-header {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: #1E1E1E;
}

.service-body {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #5B5B5B;
}

.services-txt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 32px;
}

.services-title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #777777;
}

.services-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #1E1E1E;
    width: 78%;
}

@media (max-width: 991px){
    .services-contain{
        width: 95%;
    }
    .services-txt{
        margin-left: 20px;
    }
    .service-header{
        font-size: 20px;
    }
    .service-body{
        font-size: 14px;
    }
    .service-card{
        width: 70%;
    }
}

@media (max-width: 767px){
    .services{
        grid-template-columns: repeat(1,1fr);
        padding: 40px 0 50px 0;
        height: auto;
    }
    .services-txt{
        align-items: center;
        margin-left: 0;
    }
    .services-title{
        font-size: 16px;
    }
    .services-sub{
        font-size: 14px;
        text-align: center;
        width: 93%;
    }
    .services-contain{
        order: 1;
        width: 100%;
        margin: 40px 0 0 0;    
    }
    .service-card{
        width: 80%;
        height: 135px;
        margin: 0 0 0 5%;
    }
    .purple{
        margin: 0 0 0 10% !important;
    }
    .service-header{
        font-size: 18px;
    }
    .service-body{
        font-size: 14px;
    }
}