.privacy {
  width: 100%;
  height: auto;
  padding: 30px 100px 70px 100px;
  box-sizing: border-box;
  background: #fffcf7;
}

.priv-head {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 150%;
  color: #1e1e1e;
  margin-top: 15px;
}

.priv-body {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #1e1e1e;
  text-align: justify;
}

.priv-body span {
  font-weight: 700;
}

.green-col {
  color: #2b600b;
}

.upd {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #5b5b5b;
}


@media (max-width: 767px){
  .privacy{
    padding: 20px 40px 40px;
  }
  .upd{
    font-size: 14px;
  }
}

@media (max-width: 480px){
  .privacy{
    padding: 20px 20px 40px;
  }
  .priv-head{
    font-size: 24px;
  }
  .priv-body{
    font-size: 14px;
  }
}